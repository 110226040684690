import { Divider } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { IoCheckmarkDoneSharp } from 'react-icons/io5'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { formatDate } from '../../common/utils'
import {
  deleteTransaction,
  getMyScansReport,
  updateCategory,
  updateQuantity,
} from '../../services/transactions'
import './todaysReport.css'

const Row = ({ item, index, reload, setRecords }) => {
  const [quantity, setQuantity] = useState(0)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const timeoutIdRef = useRef(null)

  // console.log("item", item);
  useEffect(() => {
    setQuantity(item['quantity'])
    setSelectedCategory(item['category'])
  }, [item])

  const onDeleteTransaction = async () => {
    const response = await deleteTransaction(item['_id'])
    if (response['status'] == 'success') {
      toast.success('successfully deleted transaction')
      // reload()
      setRecords((prev) => prev?.filter((r) => r._id !== item['_id']))
    } else {
      toast.error(response['error'])
    }
  }

  const updateQuantityWithDebounce = (value) => {
    const debounceDelay = 500

    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current)
    }

    timeoutIdRef.current = setTimeout(() => {
      onUpdateQuantity(value)
    }, debounceDelay)
  }

  const onUpdateQuantity = async (value) => {
    console.log(`updating quantities`)
    const response = await updateQuantity({
      quantity: value === '' ? '1' : value,
      transactionId: item['_id'],
      platform: 'Auto - Quantity',
    })
    if (response['status'] == 'success') {
      toast.success('successfully updated quantities')
      // reload()
    } else {
      toast.error(response['error'])
    }
  }
  const onUpdateCategory = async (category) => {
    console.log(`updating Category`)
    const response = await updateCategory({
      category,
      transactionId: item['_id'],
      platform: 'Auto - Category',
    })
    if (response['status'] == 'success') {
      toast.success('successfully updated Category')
      // reload()
    } else {
      toast.error(response['error'])
    }
  }

  return (
    <tr key={index}>
      <td>{item?.category === 'TOTAL QTY' ? '' : index + 1}</td>
      <td>{item['type']}</td>
      <td>{item['associatedWith']}</td>
      <td>{item['product']['SKU']}</td>
      <td>{item['product']['BATCHNUMBER']}</td>
      <td>{item['product']['MRP']}</td>
      <td>{formatDate(item['product']['EXPIRYDATE'])}</td>
      <td>{formatDate(item['product']['MFG_DATE'])}</td>
      {/* {item?.product?.optional2 !== "" && <td>{item?.product?.optional2}</td>} */}
      <td>{item?.product?.optional2 || ''}</td>
      {/* <td>{item['category']}</td> */}
      {item?.category === 'TOTAL QTY' ? (
        <td
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          TOTAL QTY
        </td>
      ) : (
        <td style={{ width: 200 }}>
          <div className="input-group mb-3">
            <select
              className="form-control"
              value={selectedCategory}
              onChange={(e) => {
                onUpdateCategory(e.target.value)
                setSelectedCategory(e.target.value)
              }}
            >
              <option></option>
              <option value="expiry">Expiry</option>
              <option value="near_expiry">Near Expiry</option>
              <option value="sellable">Sellable</option>
              <option value="breakage">Breakage</option>
              <option value="spoilt">Spoilt</option>
            </select>
            {/* <button
              onClick={onUpdateCategory}
              className='btn btn-secondary'
              id='button-addon2'
            >
              save
            </button> */}
          </div>
        </td>
      )}
      {/* <td>{item['remark']}</td> */}
      {/* <td>{item['verified'] ? 'Yes' : 'No'}</td> */}
      {item?.category === 'TOTAL QTY' ? (
        <td
          style={{
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {item?.quantity}
        </td>
      ) : (
        <td style={{ width: 150 }}>
          <div className="input-group mb-3">
            <input
              style={{ textAlign: 'center' }}
              min={1}
              type="number"
              className="form-control"
              onChange={(e) => {
                setQuantity(e.target.value)
                updateQuantityWithDebounce(e.target.value)
              }}
              defaultValue={quantity}
              value={quantity}
            ></input>
            {/* <button
              onClick={onUpdateQuantity}
              className='btn btn-secondary'
              id='button-addon2'
            >
              save
            </button> */}
          </div>
        </td>
      )}
      <td>
        {item?.category === 'TOTAL QTY' ? (
          ''
        ) : (
          <button
            onClick={onDeleteTransaction}
            className="btn btn-sm btn-danger"
          >
            delete
          </button>
        )}
      </td>
    </tr>
  )
}

const TodaysReport = () => {
  const count = useSelector((state) => state.scanResult.count)

  useEffect(() => {
    loadTodaysReport()
  }, [])

  useEffect(() => {
    loadTodaysReport()
  }, [count])

  const [records, setRecords] = useState([])
  const [showTick, setShowTick] = useState(false)
  const [length, setLength] = useState('')
  const [division, setDivision] = useState('')
  const loadTodaysReport = async () => {
    const response = await getMyScansReport()
    // console.log("RES", response);
    if (response['status'] === 'success') {
      const newLength = response.data.length

      if (response.data.length > 0) {
        // response.data.push({
        //   associatedWith: '',
        //   category: 'TOTAL QTY',
        //   date: '',
        //   lrDate: '',
        //   platform: '',
        //   product: {
        //     ITEM_CODE: '',
        //     SKU: '',
        //     BATCHNUMBER: '',
        //     EXPIRYDATE: '',
        //     MRP: '',
        //   },
        //   quantity: response.data.reduce(
        //     (total, item) => total + item.quantity,
        //     0
        //   ),
        //   receivedDate: '',
        //   remark: '',
        //   sessionId: '',
        //   type: '',
        //   user: '',
        //   verified: '',
        //   _id: '',
        // })

        // console.log(newLength);
        // console.log(length);

        if (
          (newLength > length &&
            response['data']?.[0]?.platform === 'Auto' &&
            length) ||
          (newLength === 1 &&
            response['data']?.[0]?.platform === 'Auto' &&
            newLength > length)
        ) {
          setShowTick(true)
        }
        setTimeout(() => {
          setShowTick(false)
        }, 500)
      }
      setLength(newLength)
      // passdivision(response["data"]?.[0]?.product?.optional2 || 'test')
      setDivision(response['data']?.[0]?.product?.optional2)

      // setRecords([])
      // const t = setTimeout(() => {
      setRecords(response['data'])
      // }, 500)
      console.log('records', records)
    } else {
      toast.error(response['error'])
    }
  }

  return (
    <div>
      <div>
        {showTick && (
          <div className="tick-icon">
            <IoCheckmarkDoneSharp
              style={{
                fontSize: '80px',
                color: 'white',
                background: 'green',
                borderRadius: '50%',
                padding: '10',
              }}
            />
            {division}
          </div>
        )}
        <Divider />
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Type</th>
              <th>Party Code</th>
              <th>Product Name</th>
              <th>Batch Number</th>
              <th>MRP</th>
              <th>Expiry Date</th>
              <th>Mfg Date</th>
              {/* {records[0]?.product?.optional2 !== "" && <th>Division</th>} */}
              <th>Division</th>
              <th>Category</th>
              {/* <th>Remark</th> */}
              {/* <th>Verified</th> */}
              <th>Quantities</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {records.map((item, index) => {
              return (
                <Row
                  item={item}
                  index={index}
                  key={index}
                  // reload={loadTodaysReport}
                  setRecords={setRecords}
                />
              )
            })}
          </tbody>
          {records?.length > 0 ? (
            <tfoot>
              <tr>
                <th colSpan={9}></th>
                <th style={{ textAlign: 'center' }}>TOTAL QTY</th>
                <th style={{ textAlign: 'center' }}>
                  {records.reduce((total, item) => total + item.quantity, 0)}
                </th>
                <th></th>
              </tr>
            </tfoot>
          ) : (
            <></>
          )}
        </table>
      </div>
    </div>
  )
}

export default TodaysReport
