import { BASE_URL } from './constants'
import moment from 'moment'

export function createUrl(path) {
  return BASE_URL + '/' + path
}

export const formatDate = (strDate) => {
  let formattedDate = strDate
  if (strDate && strDate.length == 8) {
    const tempDate = `${strDate.substr(6, 2)}-${strDate.substr(
      4,
      2
    )}-${strDate.substr(0, 4)}`

    formattedDate = moment(tempDate, 'DD-MM-YYYY').format('DD/MM/YYYY')
  }

  return formattedDate
}
