export class ScanResult {
  imageUrl = ''
  batchNumber = ''
  expiryDate = ''
  itemCode = ''
  mfgDate = ''
  mrp = ''
  productName = ''
  id = ''
  associateDetails = {}
  requestId = ''
  transactionId = ''
  type = ''
  verified = false
  firebaseKey = ''
  sessionId = ''
  productId = ''
  algo = ''
  remark = ''
  lrDate = ''
  receivedDate = ''
  division = ''
  inputParams = ''
  company = ''
  category = ''
}

export class ScanResultQueue {
  items = []
  processingItems = []
  constructor() {}

  addItem(item, firebaseKey) {
    const index = this.items.findIndex(
      (existingItem) => firebaseKey == existingItem.firebaseKey
    )
    console.log(`index = ${index}`)
    if (index != -1) {
      console.log('item already exists')
      return null
    }

    const indexProcessing = this.processingItems.findIndex(
      (existingItem) => firebaseKey == existingItem.firebaseKey
    )

    console.log(`indexProcessing = ${indexProcessing}`)
    if (indexProcessing != -1) {
      console.log('item already processing')
      return null
    }

    const algo = item['algo'] ? item['algo'] : ''
    // if (algo == 'bt') {
    //   return nul
    // }

    console.log('adding new item : ', firebaseKey)

    const result = new ScanResult()
    result.algo = algo
    result.productId = item['_id'] ? item['_id'] : '-NA-'
    result.inputParams = item['inputParams'] ? item['inputParams'] : '-NA-'
    result.sessionId = item['sessionId'] ? item['sessionId'] : '-NA-'
    result.firebaseKey = firebaseKey
    result.imageUrl = item['imgUrl'] ? item['imgUrl'] : ''
    result.batchNumber = item['BATCHNUMBER'] ? item['BATCHNUMBER'] : ''
    result.expiryDate = item['EXPIRYDATE']
      ? item['EXPIRYDATE'].split('-').reverse().join('-')
      : ''
    result.itemCode = item['ITEM_CODE'] ? item['ITEM_CODE'] : ''
    result.mfgDate = item['MFG_DATE']
      ? item['MFG_DATE'].split('-').reverse().join('-')
      : '--'
    result.mrp = item['MRP'] ? item['MRP'] : ''
    result.productName = item['SKU'] ? item['SKU'] : ''
    result.id = item['_id'] ? item['_id'] : '-NA-'
    result.associateDetails = item['associateDetails']
      ? JSON.parse(item['associateDetails'])
      : { name: '-NA-' }
    result.requestId = item['requestId'] ? item['requestId'] : '-NA-'
    result.transactionId = item['t_id'] ? item['t_id'] : '-NA-'
    result.type = item['type'] ? item['type'] : '-NA-'
    result.verified = false
    result.remark = item['remark'] ? item['remark'] : 'from web app'
    result.lrDate = item['lrDate'] ? item['lrDate'] : ''
    result.receivedDate = item['receivedDate'] ? item['receivedDate'] : ''
    result.division = item['optional2'] ? item['optional2'] : ''
    result.category = item['category'] ? item['category'] : ''
    result.company = item['companyId'] ? item['companyId'] : ''
    this.items.push(result)
    console.log('Result', result)
    return result
  }

  allItems() {
    const list = []
    for (const item of this.items) {
      list.push(item)
    }

    for (const item of this.processingItems) {
      list.push(item)
    }

    return list
  }

  clearProcessingQueue() {
    this.processingItems = []
  }

  popItem() {
    // wait till we are done with the previous item
    if (this.processingItems.length > 0) {
      return null
    }

    this.processingItems = []
    if (this.items.length > 0) {
      const item = this.items.shift()
      this.processingItems.push(item)
      return item
    }

    this.poppedItemKey = null
    return null
  }

  itemCount() {
    if (this.items.length == 0) {
      this.processingItems = []
    }

    return this.items.length
  }
}

export const scanResultQueue = new ScanResultQueue()
