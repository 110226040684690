import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { firebaseConfig } from '../../common/constants'
import { increment } from '../../features/resultSlice'
import { scanResultQueue } from '../../scanResultQueue'
import {
  deleteTransaction,
  updateTransaction,
} from '../../services/transactions'
import BatchNumberFinder from '../BatchNumberFinder'
import useStyles from './styles'

const { getAuth, signInAnonymously } = require('firebase/auth')

const { initializeApp } = require('firebase/app')
const { getDatabase, ref, onValue, remove } = require('firebase/database')

const ScanResult = ({ closeModal }) => {
  console.log('Parent', scanResultQueue)
  const classes = useStyles()
  const firebase = initializeApp(firebaseConfig)
  const [currentItem, setCurrentItem] = useState(null)
  const dispatch = useDispatch()
  const auth = getAuth()
  signInAnonymously(auth)
    .then(() => {
      console.log(`auth completed`)
    })
    .catch((error) => {
      console.log(error)
    })

  // listen for firebase events
  const listenFirebaseEvents = async () => {
    const database = ref(getDatabase(firebase), sessionStorage['username'])
    console.log('Database', database)
    onValue(database, (snapshot) => {
      const results = snapshot.child('/').val()
      if (results) {
        // firebase has returned some results
        console.log('[firebase]: total: ', Object.keys(results))
        for (const key of Object.keys(results)) {
          const array = results[key]
          console.log('Scan Array', array)
          if (array) {
            if (array.length == 1 && array[0]['algo'] == 'bt') {
              deleteFirebaseEntry(key)
            } else {
              scanResultQueue.addItem(array[0], key)
            }
          }
          // const result = array.length > 0 ? array[0] : null
          // if (result) {
          //   if (result['algo'] == 'bt' && result) {
          //     // this is success
          //     deleteFirebaseEntry(key)
          //   } else {
          // scanResultQueue.addItem(array[0], key)
          //   }
          // }
        }

        console.log('scanResultQueue', scanResultQueue)

        // update todays report
        dispatch(increment())

        // start processing the queue
        getNextItem()
      }
    })
  }

  useEffect(() => {
    listenFirebaseEvents()
    getNextItem()
  }, [])

  const [type, setType] = useState('')
  const [partyCode, setPartyCode] = useState('')
  const [partyName, setPartyName] = useState('')
  const [imageUrl, setImageUrl] = useState('')
  const [productName, setProductName] = useState('')
  const [productCode, setProductCode] = useState('')
  const [batch, setBatch] = useState('')
  const [mBatch, setMBatch] = useState('')
  const [mrp, setMRP] = useState('')
  const [expiryDate, setExpiryDate] = useState('')
  const [mfgDate, setMFGDate] = useState('')
  const [quantities, setQuantities] = useState('1')
  const [productId, setProductId] = useState('')
  const [transactionId, setTransactionId] = useState('')
  const [firebaseKey, setFirebaseKey] = useState('')
  const [sessionId, setSessionId] = useState('')
  const [category, setCategory] = useState('')
  const [remark, setRemark] = useState('')
  const [lrDate, setLrDate] = useState('')
  const [receivedDate, setReceivedDate] = useState('')
  const [inputParams, setInputParams] = useState('')
  const [company, setCompany] = useState('')

  console.log('trasactionID', transactionId)
  const getNextItem = () => {
    console.log(
      '[getNextItem] checking length => ',
      scanResultQueue.itemCount()
    )
    const item = scanResultQueue.popItem()
    if (!item) {
      return
    }
    setCurrentItem(item)

    console.log(
      '[getNextItem] ---- found new item in modal: ',
      scanResultQueue.itemCount()
    )
    setSessionId(item['sessionId'] ?? '')
    setType(item['type'] ?? '')
    setPartyCode(item['partyCode'] ?? '')
    setImageUrl(item['imageUrl'] ?? '')
    setProductName(item['productName'] ?? '')
    setProductCode(item['itemCode'] ?? '')
    setBatch(item['batchNumber'] ?? '')
    setMBatch(item['batchNumber'] ?? '')
    setMRP(item['mrp'] ?? '')
    setExpiryDate(item['expiryDate'] ?? '')
    setMFGDate(item['mfgDate'] ?? '')
    setQuantities('1')
    setProductId(item['id'] ?? '')
    setTransactionId(item['transactionId'] ?? '')
    setFirebaseKey(item['firebaseKey'])
    setCategory(item['category'])
    setPartyCode(
      item['associateDetails'] ? item['associateDetails']['code'] : '-NA-'
    )
    setPartyName(
      item['associateDetails'] ? item['associateDetails']['name'] : '-NA-'
    )
    setRemark(item['remark'] ? item['remark'] : 'from web app')
    setLrDate(item['lrDate'] ? item['lrDate'] : '')
    setReceivedDate(item['receivedDate'] ? item['receivedDate'] : '')
    setInputParams(item['inputParams'] ? item['inputParams'] : '')
    setCompany(item['company'] ? item['company'] : '')
    // setInputParams(item["inputParams"] ? JSON.parse(item["inputParams"])._originalInputText : "");
  }
  console.log('inputParam==>', inputParams)
  const deleteFirebaseEntry = async (key) => {
    console.log(`deleting.. ${key}`)
    const database = ref(
      getDatabase(firebase),
      sessionStorage['username'] + '/' + key
    )
    await remove(database)
  }

  console.log('productCode', productCode)
  const confirmScan = async () => {
    const na = '-NA-'
    if (productName.length == 0 || productName == na) {
      toast.warn('please enter correct product name')
    } else if (batch.length == 0 || batch == na) {
      toast.warn('please enter correct batch number')
    } else if (mrp.length == 0 || mrp == na) {
      toast.warn('please enter correct mrp')
    } else if (expiryDate.length == 0 || expiryDate == na) {
      toast.warn('please enter correct expiry date')
    } else if (quantities.length == 0 || quantities == na) {
      toast.warn('please enter quantities')
    } else if (category.length == 0 || category == na) {
      toast.warn('please select Category')
    } else {
      calculateCategories(expiryDate)
      const body = {
        sku: productName,
        productId: productId,
        type: type,
        quantity: quantities,
        expiryDate: expiryDate ? expiryDate.split('-').reverse().join('-') : '',
        mfgDate: mfgDate ? mfgDate.split('-').reverse().join('-') : '',
        batchnumber: batch,
        mrp: mrp,
        transactionId: transactionId == '-NA-' ? undefined : transactionId,
        platform:
          category !== 'expiry'
            ? 'Manual - Category'
            : quantities !== '1'
            ? 'Manual - Quantity'
            : mBatch !== batch
            ? 'Manual - Batch'
            : 'Auto - Confirm',
        verified: true,
        remark: remark,
        category: category,
        sessionId: sessionId,
        associateId: partyCode,
        lrDate: lrDate,
        receivedDate: receivedDate,
        imageUrl: imageUrl,
        ITEM_CODE: productCode,
        inputParams: inputParams,
        company,
      }

      console.log('body===========>', body)

      const response = await updateTransaction(body, transactionId)

      if (response['status'] == 'success') {
        resetItem()
        toast.success('updated transaction successfully')
        shouldContinue()
      } else {
        toast.error(response['error'])
      }
    }
  }

  // const calculateCategory = (date) => {
  //   if (date) {
  //     const expiryDateStr =
  //       date && date.length == 8
  //         ? `${date.substr(6, 2)}-${date.substr(4, 2)}-${date.substr(0, 4)}`
  //         : date;
  //     const expiryDateMoment = moment(expiryDateStr, "DD-MM-YYYY");

  //     const difference = expiryDateMoment.diff(
  //       moment().startOf("day"),
  //       "month",
  //       true
  //     );

  //     console.log(`difference in months = ${difference}`);
  //     const tmpCategory = difference <= 2 ? "expiry" : "breakage";
  //     console.log(`selected Category: ${tmpCategory}`);
  //     setCategory(tmpCategory);
  //   }
  // };

  const resetItem = () => {
    setSessionId('')
    setType('')
    setPartyCode('')
    setPartyName('')
    setImageUrl('')
    setProductName('')
    setProductCode('')
    setBatch('')
    setMBatch('')
    setMRP('')
    setExpiryDate('')
    setMFGDate('')
    setQuantities('1')
    setProductId('')
    setTransactionId('')
    setFirebaseKey('')
    setCategory('')
  }

  const shouldContinue = async () => {
    await deleteFirebaseEntry(firebaseKey)

    // mark the item is done processing
    scanResultQueue.clearProcessingQueue()

    // update todays report
    dispatch(increment())

    const count = scanResultQueue.itemCount()
    console.log(`[shouldContinue]: scan result queue size: ${count}`)
    if (count > 0) {
      getNextItem()
    } else {
      console.log(`closing.. .....`)
      setCurrentItem(null)
    }
  }

  const deleteScan = async () => {
    await deleteTransaction(transactionId)
    toast.success('successfully deleted transaction')
    shouldContinue()
  }

  const calculateCategories = (date) => {
    if (date) {
      const expiryDateMoment = moment(date, 'YYYY-MM-DD')
      const currentDateMoment = moment()
      const sixMonthsFromNow = currentDateMoment.clone().add(6, 'months')
      console.log(sixMonthsFromNow)
      console.log(expiryDateMoment)
      if (
        expiryDateMoment.isBefore(currentDateMoment) ||
        expiryDateMoment.isSame(currentDateMoment, 'month')
      ) {
        setCategory('expiry')
      } else if (expiryDateMoment.isBefore(sixMonthsFromNow)) {
        setCategory('near_expiry')
      } else {
        setCategory('')
      }
    }
  }

  return (
    <div className={classes.container}>
      <div className='card'>
        {!currentItem && (
          <div className={classes.overlay}>
            <h2 className={classes.centerTitle}>Waiting for new scan...</h2>
          </div>
        )}
        {/* {showTick && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "end",
              width: "100%",
              height: "100%",

              zIndex: 2000,
              position: "absolute",
            }}
          >
            <IoCheckmarkDoneSharp
              style={{
                fontSize: "60px",
                color: "white",
                background: "green",
                borderRadius: "50%",
                padding: "10",
              }}
            />
          </div>
        )} */}
        <div className='card-body'>
          <div className='row'>
            <div className='col'>
              <div className={classes.imageContainer}>
                <img className={classes.image} src={imageUrl} alt='' />
              </div>
            </div>
            <div className='col'>
              <div className='row'>
                <div className='col'>
                  <div className='row'>
                    <div className='col'>
                      <span className={classes.label}>Type: </span>
                      <span>{type} </span>
                    </div>
                    <div className='col'>
                      <span className={classes.label}>Party Code: </span>
                      <span>{partyName}</span>
                    </div>
                  </div>
                </div>
              </div>

              <div className='row' style={{ marginTop: 10 }}>
                <div className='row'>
                  <div className='col'>
                    <div className='row'>
                      <div className='col'>
                        <div className='mb-3'>
                          <label htmlFor=''>Batch Number</label>
                          <BatchNumberFinder
                            company={company}
                            defaultValue={batch}
                            onChange={(value) => {
                              setBatch(value)
                            }}
                            onSelectProduct={(item) => {
                              setProductId(item['_id'])
                              setBatch(item['BATCHNUMBER'])
                              setProductCode(item['ITEM_CODE'])
                              setProductName(item['SKU'])
                              setMRP(item['MRP'])

                              const expiryDates = item['EXPIRYDATE']
                              if (expiryDates.length == 8) {
                                const formattedDate = `${expiryDates.substr(
                                  0,
                                  4
                                )}-${expiryDates.substr(
                                  4,
                                  2
                                )}-${expiryDates.substr(6, 2)}`
                                console.log(`formatted date: ${formattedDate}`)
                                setExpiryDate(formattedDate)
                                calculateCategories(formattedDate)
                              }

                              const mfgDate = item['MFG_DATE']
                              if (mfgDate.length == 8) {
                                const formattedDate = `${mfgDate.substr(
                                  0,
                                  4
                                )}-${mfgDate.substr(4, 2)}-${mfgDate.substr(
                                  6,
                                  2
                                )}`
                                console.log(`formatted date: ${formattedDate}`)
                                setMFGDate(formattedDate)
                              }
                            }}
                          />
                          {/* <input
                            type='text'
                            className='form-control'
                            value={batch}
                            onChange={(e) => {
                              setBatch(e.target.value)
                            }}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='col'>
                    <div className='row'>
                      <div className='col'>
                        <div className='mb-3'>
                          <label htmlFor=''>Product Name</label>
                          <input
                            type='text'
                            className='form-control'
                            value={productName}
                            onChange={(e) => {
                              console.log(`product name: ${e.target.value}`)
                              setProductName(e.target.value)
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <div className='mb-3'>
                      <label htmlFor=''>Product Code</label>
                      <input
                        type='text'
                        className='form-control'
                        value={productCode}
                        onChange={(e) => {
                          setProductCode(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='mb-3'>
                      <label htmlFor=''>Category</label>
                      <select
                        className='form-control'
                        value={category}
                        onChange={(e) => {
                          setCategory(e.target.value)
                        }}
                      >
                        <option></option>
                        <option value='expiry'>Expiry</option>
                        <option value='near_expiry'>Near Expiry</option>
                        <option value='spoilt'>Spoilt</option>
                        <option value='sellable'>Sellable</option>
                        <option value='breakage'>Breakage</option>
                      </select>
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <div className='mb-3'>
                      <label htmlFor=''>Expiry Date</label>
                      <input
                        type='date'
                        className='form-control'
                        value={expiryDate}
                        onChange={(e) => {
                          setExpiryDate(e.target.value)
                          calculateCategories(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='mb-3'>
                      <label htmlFor=''>MFG Date</label>
                      <input
                        type='date'
                        className='form-control'
                        value={mfgDate}
                        onChange={(e) => {
                          setMFGDate(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className='row'>
                  <div className='col'>
                    <div className='mb-3'>
                      <label htmlFor=''>MRP</label>
                      <input
                        type='number'
                        className='form-control'
                        value={mrp}
                        onChange={(e) => {
                          setMRP(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                  <div className='col'>
                    <div className='mb-3'>
                      <label htmlFor=''>Quantities</label>
                      <input
                        value={quantities}
                        type='number'
                        className='form-control'
                        onChange={(e) => {
                          setQuantities(e.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='card-footer'
          style={{
            padding: 10,
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <button onClick={confirmScan} className='btn btn-success'>
              Confirm
            </button>
            <button
              onClick={deleteScan}
              className='btn btn-danger'
              style={{ marginLeft: 10 }}
            >
              Discard
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ScanResult
