import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  selectRow: {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  messageRed: {
    color: 'red',
  },
  messageBlack: {
    color: 'black',
  },
}))
