import { configureStore } from '@reduxjs/toolkit'
import scanResultReducer from './features/resultSlice'
import authReducer from './features/authSlice'

export const store = configureStore({
  reducer: {
    scanResult: scanResultReducer,
    auth: authReducer,
  },
})

export default store
