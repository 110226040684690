import React, { useEffect, useState } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
  Typography,
  Divider,
  Stack,
  Modal,
} from "@mui/material";
import useStyle from "./styles";
import { scanResultQueue } from "../../scanResultQueue";
import ScanResult from "../ScanResult";
import Report from "../TodaysReport";
import { useDispatch } from "react-redux";
import { push as queuePush } from "../../features/resultSlice";

const CurrentScanList = () => {
  const classes = useStyle();

  return (
    <Stack>
      <div className="row">
        <div className="col-9">
          <ScanResult />
        </div>
        <div className="col-3"></div>
      </div>

      <div className="row" style={{ marginTop: 20 }}>
        <div className="col">
          <Report />
        </div>
      </div>
    </Stack>
  );
};

export default CurrentScanList;
