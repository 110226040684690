export const BASE_URL = 'https://api.allscan.in/v2'
// export const BASE_URL = 'https://apiallscans.mergealldata.com/v2'
// export const BASE_URL = 'http://localhost:3000/v2'
// export const BASE_URL = 'https://stagingapi.allscan.in/v2'

export const firebaseConfig = {
  apiKey: 'AIzaSyD8k7aqIVMRhgkYTsiDCULA8UDHDOUy0sw',
  authDomain: 'thetechclan-6f064.firebaseapp.com',
  databaseURL:
    'https://thetechclan-6f064-default-rtdb.asia-southeast1.firebasedatabase.app',
  projectId: 'thetechclan-6f064',
  storageBucket: 'thetechclan-6f064.appspot.com',
  messagingSenderId: '532658052715',
  appId: '1:532658052715:web:fe09ba79913bedc3d29b2e',
  measurementId: 'G-GX5QCZHXNS',
}
