import { Checkbox, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { formatDate } from "../../common/utils";
import { getReportFormat, getTodaysReport } from "../../services/transactions";
import { saveAs } from "file-saver";
import JSZip from "jszip";
import Papa from "papaparse";
import moment from "moment";
import useStyles from "./styles";
import { format } from "date-fns";
import LoadingScreen from "../../utils/LoadingScreen";

const ExportReports = () => {
  const classes = useStyles();
  const [selectedStockist, setSelectedStockist] = useState(null);
  const [selectedStockistName, setSelectedStockistName] = useState("");
  const [selectedStockistCode, setSelectedStockistCode] = useState("");
  const stokistName = selectedStockistName?.replace(/\./g, "");
  const stokistCode = selectedStockistCode?.replace(/\./g, "");
  const fileName = `${stokistName}_${stokistCode}`;
  const count = useSelector((state) => state.scanResult.count);
  const [headers, setHeaders] = useState([]);
  const [loading, setLoading] = useState(false);
  const [stockists, setStockists] = useState([]);
  const [records, setRecords] = useState([]);
  const [fromDate, setFromDate] = useState(moment().startOf("day"));
  const [toDate, setToDate] = useState(moment().endOf("day"));
  const [selectedCategoryCheckboxes, setSelectedCategoryCheckboxes] =
    useState();
  const [selectedRemarkCheckboxes, setSelectedRemarkCheckboxes] = useState();

  const loadReportType = async () => {
    const response = await getReportFormat();
    // console.log("Response", response);
    if (response["status"] == "success") {
      setHeaders(JSON.parse(response["data"]["columns"]));
      console.log("file type", JSON.parse(response["data"]["columns"]));
    } else {
      toast.error(
        "Error while getting the report format.. please contact Orbo"
      );
    }
  };

  useEffect(() => {
    loadReportType();
  }, []);

  // useEffect(() => {
  //   loadTodaysReport()
  // }, [count])

  const loadTodaysReport = async () => {
    // console.log(`from Date = ${fromDate}, to date = ${toDate}`);
    const diff = toDate.diff(fromDate, "hours");
    if (diff <= 0) {
      toast.error("To Date must be greater than From Date");
      return;
    }
    setLoading(true);
    const response = await getTodaysReport(
      fromDate.format("DD-MM-YYYY"),
      toDate.format("DD-MM-YYYY")
    );
    if (response["status"] == "success") {
      setLoading(false);
      console.log("response", response.data);
      const data = response["data"];
      const tmpStockists = [];
      for (const entry of data) {
        if (entry.product != null) {
          const index = tmpStockists.findIndex(
            (item) => item.code == entry.associatedWith
          );

          const newEntry = { ...entry };
          newEntry["formattedExpiryDate"] = formatDate(
            entry["product"]["EXPIRYDATE"]
          );
          newEntry["formattedMFGDate"] = formatDate(
            entry["product"]["MFG_DATE"]
          );
          newEntry["platform"] =
            newEntry["platform"] == "web" ? "Manual" : newEntry["platform"];
          newEntry["date"] = moment(entry["date"])
            .local()
            .format("YYYY-MM-DD HH:mm:ss");

          // Copying the remark field to the product object
          newEntry.product.remark = entry.remark;
          newEntry.product.category = entry.category;
          // newEntry.product.platform = entry.platform;

          if (index === -1) {
            tmpStockists.push({
              code: entry.associatedWith,
              name: entry.associateDetails
                ? entry.associateDetails.name
                : entry.associatedWith,
              records: [newEntry],
            });
          } else {
            const productIndex = tmpStockists[index].records.findIndex(
              (item) =>
                item.product._id == newEntry.product._id &&
                item.product.remark === newEntry.remark &&
                item.product.category === newEntry.category
              // &&
              // item.product.platform === newEntry.platform
            );

            if (productIndex == -1) {
              tmpStockists[index].records.push(newEntry);
            } else {
              tmpStockists[index].records[productIndex].quantity +=
                entry.quantity;
            }
          }
        }
      }

      setStockists(tmpStockists);
      console.log("Response", tmpStockists);
      if (tmpStockists.length > 0) {
        setSelectedStockist(tmpStockists[0]?.code);
        setRecords(tmpStockists[0].records);
        console.log("Records==>", tmpStockists[0].records);
      }
    } else {
      toast.error(response["error"]);
    }
  };

  const onSelectStockist = (code) => {
    const index = stockists.findIndex((item) => item.code == code);
    setRecords(stockists[index].records);
  };
  // console.log("Records", records);
  const groupRecordsByCategory = () => {
    const groupedRecords = {};

    records.forEach((record) => {
      const category = record?.category || "Uncategorized";
      if (!groupedRecords[category]) {
        groupedRecords[category] = [];
      }
      groupedRecords[category].push(record);
    });
    console.log("groupedRecords", groupedRecords);
    return groupedRecords;
  };

  const getCategoryCSVLinks = () => {
    const groupedRecords = groupRecordsByCategory();
    const categoryCSVLinks = {};
    Object.keys(groupedRecords).forEach((category) => {
      if (!categoryCSVLinks[category]) {
        categoryCSVLinks[category] = true;
      }
      categoryCSVLinks[category] = true;
    });
    return categoryCSVLinks;
  };
  useEffect(() => {
    setSelectedCategoryCheckboxes(getCategoryCSVLinks());
    setSelectedRemarkCheckboxes(getRemarkCSVLinks());
    setSelectedStockistCode(records[0]?.associatedWith);
    setSelectedStockistName(records[0]?.associateDetails?.name);
  }, [records]);

  const handleCategoryCheckboxChange = (category) => {
    console.log("Category", category);
    setSelectedCategoryCheckboxes((prevState) => {
      console.log("sdfsdfsdfsdfs", prevState);
      return {
        ...prevState,
        [category]: !prevState[category],
      };
    });
  };

  console.log("selectedCategoryCheckboxes ==> ", selectedCategoryCheckboxes);

  const formatCategoryRecord = (record) => {
    const formattedRecord = {};
    headers.forEach((header) => {
      const keys = header.key.split(".");
      let value = record;
      for (let key of keys) {
        value = value[key];
      }
      formattedRecord[header.label] = value;
    });
    return formattedRecord;
  };

  const handleDownloadSelectedCategories = (
    selectedCategoryCheckboxes,
    selectedRemarkCheckboxes
  ) => {
    const groupedRecords = groupRecordsByCategory();
    const zip = new JSZip();
    console.log("selectedCategoryCheckboxes", selectedCategoryCheckboxes);
    console.log("selectedRemarkCheckboxes", selectedRemarkCheckboxes);
    console.log("value", Object.values(selectedRemarkCheckboxes).some(Boolean));

    Object.keys(selectedCategoryCheckboxes).forEach((category) => {
      if (selectedCategoryCheckboxes[category]) {
        const csvData = [];
        const selectedRemarks = Object.keys(selectedRemarkCheckboxes);
        for (const record of groupedRecords[category]) {
          if (Object.values(selectedRemarkCheckboxes).some(Boolean)) {
            selectedRemarks.forEach((remark) => {
              if (
                record.remark === remark &&
                selectedRemarkCheckboxes[remark]
              ) {
                csvData.push(formatCategoryRecord(record));
              }
            });
          } else {
            csvData.push(formatCategoryRecord(record));
          }
        }
        console.log("csvData", csvData);
        const csvHeaders = headers.map((header) => header.label);

        const csvContent = Papa.unparse({
          fields: csvHeaders,
          data: csvData,
        });

        zip.file(`${fileName}(${category}).csv`, csvContent);
      }
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "SelectedCategories.zip");
    });
  };

  const handleDownloadSelectedRemark = (
    selectedCategoryCheckboxes,
    selectedRemarkCheckboxes
  ) => {
    const groupedRecords = groupRecordsByRemark();
    const zip = new JSZip();

    const formatRecords = (record, headers) => {
      const formattedRecord = {};
      headers.forEach((header) => {
        const keys = header.key.split(".");
        let value = record;
        for (const key of keys) {
          value = value ? value[key] : "";
        }
        if (keys.includes("formattedExpiryDate")) {
          value = formatDate(value);
        }
        formattedRecord[header.label] = value;
      });
      return formattedRecord;
    };
    const processRecord = (record, remark, csvData) => {
      const formattedRecord = formatRecords(record, headers);
      csvData.push(formattedRecord);
    };

    const generateCSVContent = (data, remark) => {
      const csvHeaders = headers.map((header) => header.label);
      const csvContent = Papa.unparse({
        fields: csvHeaders,
        data: data,
      });
      zip.file(`${fileName}(${remark}).csv`, csvContent);
    };

    Object.keys(selectedRemarkCheckboxes).forEach((remark) => {
      if (selectedRemarkCheckboxes[remark]) {
        const csvData = [];
        const records = groupedRecords[remark];
        for (const record of records) {
          if (Object.values(selectedCategoryCheckboxes).some(Boolean)) {
            Object.keys(selectedCategoryCheckboxes).forEach((category) => {
              if (
                selectedCategoryCheckboxes[category] &&
                record.remark === category
              ) {
                processRecord(record, remark, csvData);
              }
            });
          } else {
            processRecord(record, remark, csvData);
          }
        }

        generateCSVContent(csvData, remark);
      }
    });

    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "SelectedRemarks.zip");
    });
  };

  const groupRecordsByRemark = () => {
    const groupedRecords = {};

    records.forEach((record) => {
      const remark = record?.remark || "Uncategorized";
      if (!groupedRecords[remark]) {
        groupedRecords[remark] = [];
      }
      groupedRecords[remark].push(record);
    });
    return groupedRecords;
  };

  // Get CSV links for remarks
  const getRemarkCSVLinks = () => {
    const groupedRecords = groupRecordsByRemark();
    const remarkCSVLinks = {};
    Object.keys(groupedRecords).forEach((remark) => {
      if (!remarkCSVLinks[remark]) {
        remarkCSVLinks[remark] = true;
      }
      remarkCSVLinks[remark] = true;
    });
    console.log("remarkCSVLinks", remarkCSVLinks);
    return remarkCSVLinks;
  };

  // Handle change in remark checkboxes
  const handleRemarkCheckboxChange = (remark) => {
    setSelectedRemarkCheckboxes((prevState) => ({
      ...prevState,
      [remark]: !prevState[remark],
    }));
  };

  // Download selected remarks

  console.log("selectedRemarkCheckboxes ==> ", selectedRemarkCheckboxes);
  function formatDateToLocal(utcDate) {
    const date = new Date(utcDate);
    return format(date, "MMMM d, yyyy HH:mm:ss");
  }
  return (
    <div>
      <Typography
        variant="h5"
        style={{ textAlign: "center", marginBottom: 20 }}
      >
        Download Report
      </Typography>

      <div className="container">
        {loading && (
          <LoadingScreen
            loading={loading}
            message={"Loading report, please wait.."}
          />
        )}
        <div className="row">
          <div className="col">
            <div className="mb-3">
              <label htmlFor="">From Date</label>
              <input
                type="date"
                defaultValue={moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  setFromDate(
                    moment(e.target.value, "YYYY-MM-DD").startOf("day")
                  );
                }}
                className="form-control"
              />
            </div>
          </div>
          <div className="col">
            <div className="mb-3">
              <label htmlFor="">To Date</label>
              <input
                type="date"
                defaultValue={moment().format("YYYY-MM-DD")}
                onChange={(e) => {
                  setToDate(moment(e.target.value, "YYYY-MM-DD").endOf("day"));
                }}
                className="form-control"
              />
            </div>
          </div>
          <div className="col d-flex align-items-end pb-3">
            <button className="btn btn-success" onClick={loadTodaysReport}>
              Create Report
            </button>
          </div>
        </div>

        {loading && <div>Loading report, please wait..</div>}
        {!loading && stockists.length !== 0 && (
          <p className="font-weight-bold">Report data available</p>
        )}
        {!loading && stockists.length == 0 && (
          <p className="font-weight-bold text-danger">
            Report data not yet available
          </p>
        )}
      </div>

      <hr />

      {stockists.length > 0 && (
        <div
          style={{
            display: "flex",
            marginTop: -10,

            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: -10,
            }}
          >
            <text
              style={{
                margin: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Category :
            </text>
            {Object.keys(selectedCategoryCheckboxes).map((category) => (
              <div
                key={category}
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={selectedCategoryCheckboxes[category]}
                  onChange={() => handleCategoryCheckboxChange(category)}
                />
                <Typography style={{ marginLeft: "5px" }}>
                  {category}
                </Typography>
              </div>
            ))}
          </div>
          <div
            style={{
              margin: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              width: 400,
            }}
          >
            <div>
              <span class="input-group-text" id="basic-addon1">
                Stockist
              </span>
            </div>
            <select
              className="form-control"
              onChange={(e) => {
                onSelectStockist(e.target.value);
              }}
            >
              {stockists.map((item) => {
                return <option value={item.code}>{item.name}</option>;
              })}
            </select>
            {records.length > 0 &&
            Object.values(selectedCategoryCheckboxes).some(Boolean) ? (
              <button
                className="btn btn-primary float-end"
                onClick={() =>
                  handleDownloadSelectedCategories(
                    selectedCategoryCheckboxes,
                    selectedRemarkCheckboxes
                  )
                }
              >
                Download
              </button>
            ) : Object.values(selectedRemarkCheckboxes).some(Boolean) ? (
              <button
                className="btn btn-primary float-end"
                onClick={() =>
                  handleDownloadSelectedRemark(
                    selectedCategoryCheckboxes,
                    selectedRemarkCheckboxes
                  )
                }
              >
                Download
              </button>
            ) : (
              <button className="btn btn-primary float-end">
                <CSVLink
                  headers={headers}
                  data={records}
                  filename={fileName}
                  style={{ textDecoration: "none", color: "#fff" }}
                >
                  Download
                </CSVLink>
              </button>
            )}
          </div>
        </div>
      )}
      {stockists.length > 0 && (
        <div
          style={{
            display: "flex",
            marginTop: -10,
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              marginTop: -10,
            }}
          >
            <text
              style={{
                margin: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Remark :
            </text>
            {Object.keys(selectedRemarkCheckboxes).map((category) => (
              <div
                key={category}
                style={{
                  margin: "10px",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Checkbox
                  checked={selectedRemarkCheckboxes[category]}
                  onChange={() => handleRemarkCheckboxChange(category)}
                />
                <Typography style={{ marginLeft: "5px" }}>
                  {category}
                </Typography>
              </div>
            ))}
          </div>
        </div>
      )}
      <Divider style={{ marginBottom: 20 }} />

      {records.length > 0 && stockists.length > 0 && (
        <table className="table table-striped" style={{ minWidth: 1000 }}>
          <thead>
            <tr>
              <th>#</th>
              <th>Type</th>
              {/* <th>Party Code</th> */}
              <th>Product Name</th>
              <th>Batch Number</th>
              <th>MRP</th>
              <th>Quantity</th>
              <th>Expiry Date</th>
              <th>Mfg Date</th>
              <th>Category</th>
              <th>Remark</th>
              {/* <th>Verified</th> */}
            </tr>
          </thead>
          <tbody>
            {records.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    {item?.product?.BATCHNUMBER !== "TOTAL" ? index + 1 : ""}
                  </td>
                  <td>{item?.type}</td>
                  {/* <td>{item["associatedWith"]}</td> */}
                  <td>{item?.product?.SKU}</td>
                  <td
                    style={{
                      fontWeight:
                        item?.product?.BATCHNUMBER !== "TOTAL" ? "" : "bold",
                    }}
                  >
                    {item?.product?.BATCHNUMBER || "TOTAL"}
                  </td>
                  <td
                    style={{
                      fontWeight:
                        item?.product?.BATCHNUMBER !== "TOTAL" ? "" : "bold",
                    }}
                  >
                    {item?.product?.MRP || item?.total}
                  </td>
                  <td
                    style={{
                      width: 150,
                      fontWeight:
                        item?.product?.BATCHNUMBER !== "TOTAL" ? "" : "bold",
                    }}
                  >
                    {item?.quantity}
                  </td>
                  <td>{formatDate(item?.product?.EXPIRYDATE)}</td>
                  <td>{formatDate(item?.product?.MFG_DATE)}</td>
                  <td>{item?.category}</td>
                  <td
                    style={{
                      fontWeight:
                        item?.product?.BATCHNUMBER !== "TOTAL" ? "" : "bold",
                    }}
                  >
                    {item?.remark}
                  </td>
                  {/* <td>{item["verified"] ? "Yes" : "No"}</td> */}
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default ExportReports;
