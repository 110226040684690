import { Box, Button, Container, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login as loginAction } from '../../features/authSlice'
import { login } from '../../services/userService'
import useStyles from './styles'

const Login = () => {
  const styles = useStyles()
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const loginUser = async () => {
    if (username.length === 0) {
      toast.warn('please enter user name first')
    } else if (password.length === 0) {
      toast.warn('please enter password first')
    } else {
      const response = await login(username, password)
      if (response['status'] === 'success') {

        
        const data = response['data']
  

          
          toast.success('Welcome to the AllScan!!!')
          
        sessionStorage.setItem('username', username)
        sessionStorage.setItem('name', data['name'])
        sessionStorage.setItem('token', data['token'])
        sessionStorage.setItem('email', data['email'])
        sessionStorage.setItem('reportType', data['reportType'])
        sessionStorage.setItem('erpCode', data['erpCode'] ?? false)
        
      
        dispatch(loginAction(data))

              
        if(data['erpCode']) {
          navigate('/erp-table')

        } else {
        
        navigate('/current-scans')
           
      }
   
      } else {
        toast.error(response['error'])
      }
    }
  }

  return (
    <Container maxWidth='xs'>
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component='h1' variant='h5'>
          Sign in
        </Typography>
        <Box sx={{ mt: 1 }}>
          <TextField
            margin='normal'
            fullWidth
            label='User name'
            autoFocus
            onChange={(e) => {
              setUsername(e.target.value)
            }}
          />
          <TextField
            margin='normal'
            fullWidth
            label='Password'
            type='password'
            onChange={(e) => {
              setPassword(e.target.value)
            }}
          />
          <Button
            onClick={loginUser}
            fullWidth
            variant='contained'
            sx={{ mt: 3, mb: 2 }}
          >
            Sign In
          </Button>
        </Box>
      </Box>
    </Container>
  )
}

export default Login
