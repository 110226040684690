import { makeStyles } from '@mui/styles'

export default makeStyles((theme) => ({
  overlay: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  centerTitle: {
    margin: 'auto',
    width: 500,
    textAlign: 'center',
    marginTop: 20,
  },
  container: {
    display: 'flex',
    flex: 1,
  },
  infoContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  imageContainer: {
    margin: 'auto',
    marginRight: 20,
    height: 150,
    border: 'solid gray',
    boxShadow: `0.1em 0.1em 0.5em rgb(64, 64, 70)`,
    borderRadius: 10,
  },
  image: {
    height: '100%',
    width: '100%',
    border: 'solid gray 1px',
    borderRadius: 10,
  },
  label: {
    fontWeight: 'bold !important',
    marginRight: '10px !important ',
  },
  resultContainer: {
    padding: 20,
    flex: 1,
  },
}))
