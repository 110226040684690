import { Typography } from "@mui/material";
import { parse } from "papaparse";
import React, { useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-toastify";

const CompareFiles = () => {
  const [file1, setFile1] = useState("");
  const [file2, setFile2] = useState("");
  const [records, setRecords] = useState([]);

  const fileName = "difference";
  const headers = [
    { label: "Distributor", key: "Distributor" },
    { label: "Batch Number", key: "Batch" },
    { label: "Product Code", key: "ItemCode" },
    { label: "Product Name", key: "ProductName" },
    { label: "Original Quantities", key: "Quantity1" },
    { label: "Scanned Quantities", key: "Quantity2" },
    { label: "Difference", key: "Difference" },
  ];

  const readFile = (file, type) => {
    const reader = new FileReader();
    reader.onloadend = (e) => {
      if (type == "file1") {
        setFile1(reader.result);
      } else {
        setFile2(reader.result);
      }
    };
    reader.readAsText(file);
  };

  const compare = () => {
    if (file1.length === 0) {
      toast.error("please select first file");
    } else if (file2.length === 0) {
      toast.error("please select second file");
    } else {
      const contents1 = parse(file1, { header: true });
      const contents2 = parse(file2, { header: true });
      const results = [];
      for (let index1 = 0; index1 < contents1.data.length; index1++) {
        const item1 = contents1.data[index1];
        let found = false;
        for (let index = 0; index < contents2.data.length; index++) {
          const item2 = contents2.data[index];
          const stockistCode1 = item1["Stockist Code"];
          const batchNumber1 =
            item1["Batch Number"] !== undefined
              ? item1["Batch Number"]
              : item1["Batch NO"];
          const stockistCode2 = item2["Stockist Code"];
          const batchNumber2 =
            item2["Batch Number"] !== undefined
              ? item2["Batch Number"]
              : item2["Batch NO"];
          if (stockistCode1 == stockistCode2 && batchNumber1 == batchNumber2) {
            found = true;
            results.push({
              Distributor:
                item1["Stockist Code"] !== undefined
                  ? item1["Stockist Code"]
                  : "-",
              Batch:
                item1["Batch Number"] !== undefined
                  ? item1["Batch Number"]
                  : item1["Batch NO"],
              Quantity1:
                item1["Quantities"] !== undefined
                  ? item1["Quantities"]
                  : item1["QTY"],
              Quantity2:
                item2["Quantities"] !== undefined
                  ? item2["Quantities"]
                  : item2["QTY"],
              Difference:
                item1["Quantities"] !== undefined
                  ? Math.abs(item1["Quantities"] - item2["Quantities"])
                  : Math.abs(item1["QTY"] - item2["QTY"]),
              ItemCode:
                item1["Item Code"] !== undefined
                  ? item1["Item Code"]
                  : item1["SAP CODE"],
              ProductName: item1["Product Name"],
            });

            contents2.data.splice(index, 1);
            break;
          }
        }

        if (!found) {
          results.push({
            Distributor:
              item1["Stockist Code"] !== undefined
                ? item1["Stockist Code"]
                : "-",
            Batch:
              item1["Batch Number"] !== undefined
                ? item1["Batch Number"]
                : item1["Batch NO"],
            Quantity1:
              item1["Quantities"] !== undefined
                ? item1["Quantities"]
                : item1["QTY"],
            Quantity2: "NA",
            Difference:
              item1["Quantities"] !== undefined
                ? item1["Quantities"]
                : item1["QTY"],
            ItemCode:
              item1["Item Code"] !== undefined
                ? item1["Item Code"]
                : item1["SAP CODE"],
            ProductName: item1["Product Name"],
          });
        }
      }

      for (let index = 0; index < contents2.data.length; index++) {
        const item = contents2.data[index];
        results.push({
          Distributor:
            item["Stockist Code"] !== undefined ? item["Stockist Code"] : "-",
          Batch:
            item["Batch Number"] !== undefined
              ? item["Batch Number"]
              : item["Batch NO"],
          Quantity1: "NA",
          Quantity2:
            item["Quantities"] !== undefined ? item["Quantities"] : item["QTY"],
          ItemCode:
            item["Item Code"] !== undefined
              ? item["Item Code"]
              : item["SAP CODE"],
          ProductName: item["Product Name"],
          Difference:
            item["Quantities"] !== undefined ? item["Quantities"] : item["QTY"],
        });
      }

      setRecords(results);
    }
  };

  return (
    <div>
      <Typography
        variant="h5"
        style={{ textAlign: "center", marginBottom: 20 }}
      >
        Compare Files
      </Typography>

      <div className="row">
        <div className="col">
          <div className="input-group mb-3">
            <div className="input-group-prepend" style={{ marginRight: 5 }}>
              <span className="input-group-text" id="basic-addon1">
                Stockist Claim
              </span>
            </div>
            <input
              onChange={(e) => {
                readFile(e.target.files[0], "file1");
              }}
              accept="text/csv"
              type="file"
              className="form-control"
              placeholder="file 1"
            />
          </div>
        </div>
        <div className="col">
          <div className="input-group mb-3">
            <div className="input-group-prepend" style={{ marginRight: 5 }}>
              <span className="input-group-text" id="basic-addon1">
                CFA Scanned
              </span>
            </div>
            <input
              onChange={(e) => {
                readFile(e.target.files[0], "file2");
              }}
              accept="text/csv"
              type="file"
              className="form-control"
              placeholder="file 2"
            />
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col">
          <button onClick={compare} className="btn btn-success">
            Compare
          </button>
          <button
            className="btn btn-primary float-end"
            disabled={!records?.length}
          >
            <CSVLink
              headers={headers}
              data={records}
              filename={fileName}
              style={{ textDecoration: "none", color: "#fff" }}
            >
              Download
            </CSVLink>
          </button>
        </div>
      </div>
      <hr />

      {records.length > 0 && (
        <table className="table table-striped">
          <thead>
            <tr>
              <th>#</th>
              <th>Distributor</th>
              <th>Product Code</th>
              <th>Product Name</th>
              <th>Batch Number</th>
              <th>Original Quantity</th>
              <th>Scanned Quantity</th>
              <th>Difference</th>
            </tr>
          </thead>

          <tbody>
            {records.map((item, index) => {
              return (
                <tr>
                  <td>{index + 1}</td>
                  <td>{item["Distributor"]}</td>
                  <td>{item["ItemCode"]}</td>
                  <td>{item["ProductName"]}</td>
                  <td>{item["Batch"]}</td>
                  <td>{item["Quantity1"]}</td>
                  <td>{item["Quantity2"]}</td>
                  <td>{item["Difference"]}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default CompareFiles;
