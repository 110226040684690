import axios from 'axios'
import { createUrl } from '../common/utils'

export const getCurrentScans = async (phone, password) => {
  try {
    const header = { headers: { token: sessionStorage['token'] } }
    const url = createUrl('user/login')
    const response = await axios.get(url, header)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const getReportFormat = async () => {
  try {
    const header = { headers: { token: sessionStorage['token'] } }
    const url = createUrl('report/' + sessionStorage['reportType'])
    const response = await axios.get(url, header)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const getTodaysReport = async (fromDate, toDate) => {
  try {
    const header = { headers: { token: sessionStorage['token'] } }
    const url = createUrl('transaction/today-web')
    const response = await axios.post(
      url,
      { verified: true, fromDate, toDate },
      header
    )
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const getMyScansReport = async () => {
  try {
    const header = { headers: { token: sessionStorage['token'] } }
    const url = createUrl('transaction/myscans-web')
    const response = await axios.post(url, { verified: true }, header)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const updateTransaction = async (body, transactionId) => {
  try {
    const header = { headers: { token: sessionStorage['token'] } }
    const url = createUrl('transaction/update-transaction')
    const response = await axios.put(url, body, header)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const updateQuantity = async (body) => {
  try {
    console.log(body)
    const header = { headers: { token: sessionStorage['token'] } }
    const url = createUrl('transaction/update-quantity')
    const response = await axios.put(url, body, header)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const updateCategory = async (body) => {
  try {
    console.log(body)
    const header = { headers: { token: sessionStorage['token'] } }
    const url = createUrl('transaction/update-category')
    const response = await axios.put(url, body, header)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}

export const deleteTransaction = async (id) => {
  try {
    const header = { headers: { token: sessionStorage['token'] } }
    const url = createUrl('transaction/' + id)
    const response = await axios.delete(url, header)
    return response.data
  } catch (error) {
    throw { status: 'error', error }
  }
}
