import axios from 'axios'
import { createUrl } from '../common/utils'

export const login = async (phone, password) => {
  try {
    const body = {
      phone,
      password,
    }
    const url = createUrl('user/login')
    const response = await axios.post(url, body)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}
