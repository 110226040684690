import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { findProductsByBatchNumber } from '../../services/productService'
import useStyles from './styles'

const BatchNumberFinder = ({
  defaultValue,
  company,
  onChange,
  onSelectProduct,
}) => {
  const classes = useStyles()
  const [value, setValue] = useState(defaultValue)
  const [products, setProducts] = useState([])
  const [debouncedValue, setDebouncedValue] = useState(defaultValue)
  const [message, setMessage] = useState(
    'Please type batch number to find the product'
  )

  const searchProducts = async () => {
    setProducts([])
    setMessage(`searching products by ${debouncedValue} .....`)
    const response = await findProductsByBatchNumber(debouncedValue, company)
    console.log(response)
    if (response['status'] === 'success') {
      setProducts(response['data'])
    } else {
      toast.error(response['error'])
    }
  }

  useEffect(() => {
    if (debouncedValue.length < 3) {
      setMessage('requires at least 3 characters to search')
    } else {
      if (products.length == 0) {
        setMessage(`no product found`)
      } else {
        setMessage(`${products.length} products found`)
      }
    }
  }, [products])

  useEffect(() => {
    const debounceTimer = setTimeout(() => {
      setDebouncedValue(value)
    }, 250)

    return () => clearTimeout(debounceTimer)
  }, [value])

  useEffect(() => {
    if (debouncedValue && debouncedValue.length > 2) {
      searchProducts()
    } else {
      setProducts([])
    }
  }, [debouncedValue])

  useEffect(() => {
    setProducts([])
  }, [defaultValue])

  return (
    <div style={{ maxHeight: 300 }}>
      <input
        type='text'
        value={defaultValue}
        onChange={(e) => {
          onChange(e.target.value)
          setValue(e.target.value)
        }}
        className='form-control'
        placeholder='batch number'
      />
      <div style={{ maxHeight: 250, overflow: 'scroll' }}>
        <div
          style={{ marginTop: 10 }}
          className={products.length == 0 ? 'messageRed' : 'messageBlack'}
        >
          {message}
        </div>

        <table className='table table-striped'>
          <thead>
            <tr>
              <th style={{ width: 80 }}></th>
              <th style={{ width: 100 }}>Batch</th>
              <th style={{ width: 100 }}>SKU</th>
              <th style={{ width: 80 }}>MRP</th>
              <th style={{ width: 100 }}>Expiry</th>
            </tr>
          </thead>
          <tbody>
            {products.map((item, index) => {
              return (
                <tr key={index}>
                  <td>
                    <button
                      onClick={() => {
                        onSelectProduct(item)
                        setProducts([])
                      }}
                      className='btn btn-sm btn-success'
                    >
                      Select
                    </button>
                  </td>
                  <td>
                    {item['obatch']
                      ? item['BATCHNUMBER'] + ' (' + item['obatch'] + ')'
                      : item['BATCHNUMBER']}
                  </td>
                  <td>{item['SKU']}</td>
                  <td>{item['MRP']}</td>
                  <td>{item['EXPIRYDATE']}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default BatchNumberFinder
