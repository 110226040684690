import React from "react";
import { ScaleLoader } from "react-spinners";
import { HashLoader } from "react-spinners";

const LoadingScreen = ({ loading, message }) => {
  return (
    loading && (
      <div
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 9999,
          background: "rgba(255, 255, 255, 0.8)",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "100vh",
          }}
        >
          <text
            style={{ color: "#3874CB", marginBottom: "20px", fontSize: 20, fontWeight: 'bold' }}
          >
            {message}
          </text>
          <HashLoader
            css={{
              display: "block",
              borderColor: "red",
            }}
            size={150}
            color={"#3874CB"}
            loading={loading}
          />
        </div>
      </div>
    )
  );
};

export default LoadingScreen;
