import { createSlice } from '@reduxjs/toolkit'

export const scanResultSlice = createSlice({
  name: 'results',
  initialState: { results: [], count: 0 },
  reducers: {
    increment: (state) => {
      state.count += 1
    },
    push: (state, action) => {
      state.results = action.payload
    },
    reset: (state) => {
      state.results = []
      state.count = 0
    },
  },
})

export const { push, pop, increment } = scanResultSlice.actions
export default scanResultSlice.reducer
