import { CssBaseline } from '@mui/material'
import React, { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import Login from '../Login'
import NavBar from '../NavBar'
import useStyles from './styles'

import { useDispatch, useSelector } from 'react-redux'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { login as loginAction } from '../../features/authSlice'
import CompareFiles from '../CompareReport'
import CurrentScanList from '../CurrentScanList'
import ExportReports from '../ExportReports'
import TodaysReport from '../TodaysReport'
import ErpSessionsTable from '../Erp_Session/ERP_Sessions/erpSessionsTable'

const App = () => {
  const classes = useStyles()
  const authStatus = useSelector((state) => state.auth.status)
  const dispatch = useDispatch()

  useEffect(() => {
    checkUserLogin()
  }, [])

  const checkUserLogin = () => {
    if (sessionStorage['username']) {
      console.log('updating redux')
      dispatch(loginAction())
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline>
        {authStatus && <NavBar />}
        <main className={classes.content}>
          <div className={classes.toolbar}></div>
          <Routes>
            <Route path="/" element={<Login />}></Route>

            <Route path="/current-scans" element={<CurrentScanList />}></Route>
            <Route path="/todays-report" element={<TodaysReport />}></Route>
            <Route path="/export-reports" element={<ExportReports />}></Route>
            <Route path="/compare-files" element={<CompareFiles />}></Route>
            <Route
              path="/erp-table"
              element={<ErpSessionsTable></ErpSessionsTable>}
            ></Route>
          </Routes>
        </main>
      </CssBaseline>
      <ToastContainer autoClose={1000} />
    </div>
  )
}

export default App
