import { makeStyles } from '@mui/styles'

export default makeStyles(() => ({
  container: {
    backgroundColor: 'red',
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))
