import axios from 'axios'
import { createUrl } from '../common/utils'

export const findProductsByBatchNumber = async (batch, company) => {
  try {
    const header = { headers: { token: sessionStorage['token'] } }
    let url = createUrl('product/search-by-batchnumber/' + batch)
    if (company && company.length > 0) {
      url += `?company=${company}`
    }
    const response = await axios.get(url, header)
    return response.data
  } catch (error) {
    return { status: 'error', error }
  }
}
