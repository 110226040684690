import { AccountCircle } from "@mui/icons-material";
import { AppBar, Button, Toolbar, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { Link, useNavigate } from "react-router-dom";
import { firebaseConfig } from "../../common/constants";
import useStyles from "./styles";
const { initializeApp } = require("firebase/app");
const { getAuth, signInAnonymously } = require("firebase/auth");
const { getDatabase, ref, onValue, remove } = require("firebase/database");

const isAuthenticated = true;

const NarBar = () => {
  const classes = useStyles();
  const isMobile = useMediaQuery("(max-width:600px)");
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = useState(false);
  const firebase = initializeApp(firebaseConfig);

  const auth = getAuth();
  signInAnonymously(auth)
    .then(() => {
      console.log("auth completed");
    })
    .catch((error) => {
      console.log(error);
    });

  const navigate = useNavigate();

  const logout = () => {
    confirmAlert({
      title: "Confirmation",
      message:
        "Are you sure you want to logout ? This action will clear your current queue containing pending changes.",
      buttons: [
        {
          label: "Yes",
          onClick: async () => {
            // removing all pending items in the queue
            const database = ref(
              getDatabase(firebase),
              sessionStorage["username"]
            );
            await remove(database);

            // remove the session storage items
            sessionStorage.removeItem("username");
            sessionStorage.removeItem("name");
            sessionStorage.removeItem("token");
            sessionStorage.removeItem("email");
            sessionStorage.removeItem("reportType");
            sessionStorage.removeItem("erpCode");
            navigate("/");
          },
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  return (
    <>
      <AppBar position="fixed">
        <Toolbar className={classes.toolbar}>
          {/* <IconButton onClick={() => {}} color='inherit' sx={{ ml: 1 }}>
            {theme.palette.mode == 'dark' ? <Brightness7 /> : <Brightness4 />}
          </IconButton> */}
          <div>
            <Button
              component={Link}
              to={sessionStorage['erpCode'] ? '/erp-table' :"/current-scans"}
              color="inherit"
              className={classes.linkButton}
              onClick={() => {}}
            >
              Home
            </Button>
       {!sessionStorage[
        'erpCode'
       ] && (
        <>
             <Button
              component={Link}
              to={"/export-reports"}
              color="inherit"
            className={classes.linkButton}
              onClick={() => {}}
            >
              Report
            </Button>
            <Button
              component={Link}
              to={"/compare-files"}
              color="inherit"
              className={classes.linkButton}
              onClick={() => {}}
            >
              Compare Files
            </Button>
        </>
       )}
          </div>

          <div>
            {!isAuthenticated ? (
              <Button color="inherit" onClick={() => {}}>
                Login &nbsp;
                <AccountCircle />
              </Button>
            ) : (
              <>
              {!sessionStorage['erpCode'] && (

                <Button
                component={Link}
                to={"/profile/:id"}
                color="inherit"
                className={classes.linkButton}
                onClick={() => {}}
                >
                  {!isMobile && <> {sessionStorage["name"]} </>}
                </Button>
                )}
                <Button
                  color="inherit"
                  className={classes.linkButton}
                  onClick={logout}
                >
                  Logout
                </Button>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>
    </>
  );
};

export default NarBar;
