import React, { useEffect, useMemo, useState } from 'react'
import axios from 'axios'
import {
  Container,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  Button,
} from '@mui/material'
import moment from 'moment'

const BASE_URL = 'https://api.allscan.in/v2/sessions/apiv2/data'

const DATE_FORMAT = 'YYYY-MM-DD'

function ErpSessionsTable() {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [filterData, setFilterData] = useState([])
  const [fromDate, setFromDate] = useState(moment().format(DATE_FORMAT))
  const [toDate, setToDate] = useState(
    moment(new Date()).add(1, 'days').format(DATE_FORMAT)
  )
  const [selectedErpCode, setSelectedErpCode] = useState('')
  const [erpCodesForDate, setErpCodesForDate] = useState([])

  const fetchData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(
        BASE_URL + '/' + sessionStorage['erpCode']
      )
      if (response.data.status === 'success') {
        const unformattedData = response.data.data
        const formattedData = combineAndFilterData(
          unformattedData.providers,
          unformattedData.sessions
        )
        setData(formattedData)
      } else {
        console.error('Failed to fetch data')
      }
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  const updateErpCodesForDate = (formattedData) => {
    const erpCodes = formattedData.map((item) => item.erpCode)
    setErpCodesForDate(Array.from(new Set(erpCodes)))
  }

  const handleFromDateChange = (event) => {
    const newFromDate = moment(event.target.value).format(DATE_FORMAT)
    setFromDate(newFromDate)
  }

  const handleToDateChange = (event) => {
    const newToDate = moment(event.target.value).format(DATE_FORMAT)
    setToDate(newToDate)
  }

  const handleErpCodeChange = (event) => {
    const selectedErpCodeValue = event.target.value
    setSelectedErpCode(selectedErpCodeValue)
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    const filteredData = data.filter((item) => {
      const isSameDate = moment(fromDate).isSame(toDate, 'day')
      const isWithinRange = moment(item.loginDate).isBetween(
        fromDate,
        toDate,
        null,
        '[]'
      )

      if (isSameDate) {
        return moment(item.loginDate).isSame(fromDate, 'day')
      } else {
        return isWithinRange
      }
    })

    setFilterData(filteredData)
  }, [fromDate, toDate, selectedErpCode, data])

  useEffect(() => {
    updateErpCodesForDate(filterData)
  }, [filterData])

  const combineAndFilterData = (erpProvidersData, erpSessionsData) => {
    const temp = []
    const providerMap = {}

    // Populate providerMap with providers having non-null clientId and installationId
    for (const provider of erpProvidersData) {
      if (provider.clientId !== null && provider.installationId !== null) {
        const key = `${provider.clientId}_${provider.installationId}`
        providerMap[key] = provider
      }
    }

    for (const session of erpSessionsData) {
      const key = `${session.clientId}_${session.installationId}`
      const provider = providerMap[key]

      if (provider) {
        temp.push({
          name: provider.name,
          clientId: provider.clientId,
          installationId: provider.installationId,
          erpCode: provider.erpCode,
          loginDate: session.loginDate,
          address: provider.address,
        })
      }
    }
    return temp
  }

  const mergeRecords = (data) => {
    const mergedData = data.reduce((result, item) => {
      const key = `${item.erpCode}-${item.clientId}-${moment(
        item.loginDate
      ).format(DATE_FORMAT)}`

      if (!result[key]) {
        result[key] = {
          name: item.name,
          clientId: item.clientId,
          erpCode: item.erpCode,
          loginDate: item.loginDate,
          address: item.address,
          count: 1,
        }
      } else {
        result[key].count += 1
      }

      return result
    }, {})

    return Object.values(mergedData)
  }

  const mergedData = useMemo(() => mergeRecords(filterData), [filterData])

  const filteredMergedData = useMemo(() => {
    const filteredData = filterData
      .filter((md) =>
        selectedErpCode !== '' ? md.erpCode === selectedErpCode : md
      )
      .filter((md) =>
        moment(md.loginDate).isBetween(fromDate, toDate, null, '[]')
      )

    const mergedData = mergeRecords(filteredData)
    console.log(
      mergedData
        .sort((a, b) => a.erpCode.localeCompare(b.erpCode))
        .sort((a, b) => a.name.localeCompare(b.name))
    )

    return mergedData.sort((a, b) => {
      // First, compare by erpCode
      const erpCodeComparison = a.erpCode.localeCompare(b.erpCode)

      // If erpCode is the same, then compare by name
      if (erpCodeComparison === 0) {
        return a.name.localeCompare(b.name)
      }

      // Otherwise, return the result of the erpCode comparison
      return erpCodeComparison
    })
  }, [filterData, fromDate, toDate, selectedErpCode])
  const totalCount = useMemo(() => {
    if (selectedErpCode) {
      return filteredMergedData.reduce((total, item) => total + item.count, 0)
    } else {
      return mergedData.reduce((total, item) => total + item.count, 0)
    }
  }, [selectedErpCode, mergedData, filteredMergedData])

  const handleDownloadCSV = () => {
    // Extract the selected ERP code
    const selectedErpCodeForFilename = selectedErpCode
      ? `_${selectedErpCode}`
      : ''

    // Function to convert the address to 'Title Case' with only the first letter uppercase
    function titleCase(str) {
      return str
        .toLowerCase()
        .split(' ')
        .map((word) => {
          // Capitalize the first letter of each word
          // If the word is fully uppercase, lowercase it first to ensure only the first letter is capitalized
          return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        })
        .join(' ')
    }

    const mergedDataWithMergedCounts = filteredMergedData.reduce(
      (result, item) => {
        // Ensure address is a string
        const lowerCaseAddress =
          typeof item.address === 'string' ? item.address.toLowerCase() : ''

        const existingItemIndex = result.findIndex(
          (i) =>
            typeof i.address === 'string' &&
            i.address.toLowerCase() === lowerCaseAddress
        )

        if (existingItemIndex !== -1) {
          // If item exists with a similar address, update the count
          result[existingItemIndex].count += item.count
        } else {
          // Otherwise, push a new item with the address in title case
          result.push({ ...item, address: titleCase(item.address) })
        }

        return result
      },
      []
    )

    // Create CSV content
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      'Erpcode,Name,Client ID,Count,Address\n' +
      mergedDataWithMergedCounts
        .map(
          (item) =>
            `${item.erpCode},${titleCase(item.name)},${titleCase(
              item.clientId
            )},${item.count},${item.address}`
        )
        .join('\n')

    // Create a link element
    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')

    // Set filename with selected ERP code
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', `Report_${selectedErpCodeForFilename}.csv`)
    document.body.appendChild(link)

    // Click the link
    link.click()
  }

  return (
    <Container maxWidth="lg">
      {' '}
      {/* Set the maximum width for the container */}
      <Paper elevation={3} style={{ padding: '20px', marginTop: '20px' }}>
        <TableRow>
          <TableCell>
            <TextField
              id="from-date-picker"
              label="From Date"
              type="date"
              value={fromDate}
              onChange={handleFromDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </TableCell>
          <TableCell>
            <TextField
              id="to-date-picker"
              label="To Date"
              type="date"
              value={toDate}
              onChange={handleToDateChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </TableCell>
          {/* <TableCell>
            <FormControl style={{ width: 200 }}>
              <InputLabel id="demo-simple-select-label">
                Select ERPCode
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Select ERP Code"
                placeholder="Select ERP Code"
                value={selectedErpCode}
                onChange={handleErpCodeChange}
              >
                <MenuItem value="" label="All ERP's">
                  All ERP Codes
                </MenuItem>
                {erpCodesForDate
                  .slice() // Create a copy of the array
                  .sort((a, b) =>
                    a.toLowerCase().localeCompare(b.toLowerCase())
                  ) // Case-insensitive sort
                  .map((erpCode) => (
                    <MenuItem key={erpCode} value={erpCode}>
                      {erpCode}
                    </MenuItem>
                  ))}
                ))}
              </Select>
            </FormControl>
          </TableCell> */}
          <TableCell colSpan={3}></TableCell>
          <TableCell style={{ fontWeight: 'bold' }}>Total Count:</TableCell>
          <TableCell>{totalCount}</TableCell>
          <TableCell>
            <Button variant="contained" onClick={handleDownloadCSV}>
              Download Report
            </Button>
          </TableCell>
        </TableRow>

        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow style={{ backgroundColor: 'lightblue' }}>
                <TableCell>ERP Code</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Client ID</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Login Date</TableCell>
                <TableCell>Count</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow>
                  <TableCell colSpan={5}>Loading....</TableCell>
                </TableRow>
              ) : filteredMergedData.length > 0 ? (
                // filteredMergedData=[...items].sort((a, b) => a.erpCode.localeCompare(b.erpCode));

                filteredMergedData.map((item) => (
                  <TableRow
                    key={`${item.erpCode}-${item.clientId}-${item.loginDate}`}
                  >
                    <TableCell>{item.erpCode}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.clientId}</TableCell>
                    <TableCell>{item.address}</TableCell>
                    <TableCell>
                      {item.loginDate &&
                        moment(item.loginDate).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{item.count}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>No data found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  )
}

export default ErpSessionsTable
